import {
  faAngleRight,
  faCalendar,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ADMIN_API } from "../common/common";
import { postApiAdmin, postApi } from "../service";
import { Logout } from "../common/Funcation";
import moment from "moment";

const cookies = new Cookies();
export default function TransactionRecordsNew() {
  const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [isLoadingMain, setIsLoadingMain] = useState(false);
  const [pageData, setPageData] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((e: any) => e.userData);

  const HeaderData = useSelector((e: any) => e.Header);

  const userId = HeaderData?._id;

  //   const DD = useSelector((e: any) => e.domainDetails);

  useEffect(() => {
    if (userData || cookies.get("skyTokenFront")) {
      getPageData();
    }
    return () => {};
  }, [userData]);

  const getPageData = async (
    FROM: string = "",
    TO: string = "",
    FILTER: any = { reportType: "", report: "" },
    PAGE: string = "1"
  ) => {
    setIsLoadingMain(true);
    let data: any = {
      api: ADMIN_API.MY_ACCOUNT.GET_TRANSACTIONS,
      value: {
        // id: userId ? userId : userData?._id,
        page: PAGE ? PAGE : "1",
        limit: "10",
        // type:"deposit"
      },
    };
    //reportType
    //report

    // if (TO !== "") {
    //   data.value.to = TO;
    // }
    // if (FROM !== "") {
    //   data.value.from = FROM;
    // }
    // if (FILTER.reportType !== "") {
    //   data.value.type = FILTER.reportType;
    // }

    await postApi(data)
      .then(function (response) {
        console.log(response);
        setPageData(response.data.data);
        setIsLoadingMain(false);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          setIsLoadingMain(false);
          Logout();
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="mob-header">
        <div className="back">
          <img
            src="../../images/extra-icon/angle-left-white.png"
            height={22}
            onClick={() => navigate("/")}
          />
        </div>
        <div className="center">
          <h5>Transaction Records</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          <span>Help</span>
        </div>
      </div>
      <div className="filter-tr">
        <div className="badge">Today</div>
        <div className="search-icon">
          <FontAwesomeIcon icon={faFilter} />
        </div>
      </div>
      <div className="tr-rc-table">
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Txn Date</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
                            <td colSpan={2} align="left">
                                <FontAwesomeIcon icon={faCalendar} /> 20/2/2020
                            </td>
                            <td colSpan={2} align='right'>
                                <span className='outline-tag'>
                                    GMP+2
                                </span>
                            </td>
                        </tr> */}
            {pageData.data &&
              pageData.data?.results?.length > 0 &&
              pageData.data.results.map((item: any, i: any) => {
                return (
                  <>
                    <tr>
                      <td>{item?.transactionType}</td>
                      <td>{item?.amount}</td>
                      <td>
                        <span
                          className={`${
                            item?.approvalBy === null
                              ? "pending-tag"
                              : item?.approvalBy && item?.isApprove
                              ? "success-tag"
                              : "fail-tag"
                          }`}
                        >
                          {item?.approvalBy === null
                            ? "pending"
                            : item?.approvalBy && item?.isApprove
                            ? "Success"
                            : "Fail"}
                        </span>
                      </td>
                      <td>
                        {moment(item.createdAt).format("MM/DD/YYYY HH:mm:ss")}{" "}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </td>
                    </tr>
                  </>
                );
              })}
            {/* <tr>
                            <td>Deposit</td>
                            <td>55465445</td>
                            <td><span className='success-tag'>Success</span></td>
                            <td>11:11:11 <FontAwesomeIcon icon={faAngleRight}/></td>
                        </tr>
                        <tr>
                            <td>Deposit</td>
                            <td>55465445</td>
                            <td><span className='fail-tag'>Success</span></td>
                            <td>11:11:11 <FontAwesomeIcon icon={faAngleRight}/></td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
}
