import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getImageUrl } from "../common/Funcation";
import { USER_API } from "../common/common";
import { getApiLink, notifyError, postApi } from "../service";
const cookies = new Cookies();

export default function Signup(this: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DD = useSelector((e: any) => e.domainDetails);
  const [domainDetails, setDomainDetails] = useState(DD);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState({});
  const [isValidationWrong, setIsValidationWrong] = useState(false);
  const [isError, setIsError] = useState({ flag: false, msg: "" });
  const [isPassShow, setIsPassShow] = useState(false);
  const [isPassConfShow, setIsPassConfShow] = useState(false);

  const [confirmationCode, setConfirmationCode] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    validationCode: "",
    mobileNumber: "",
    currency: "BDT",
    firstName: "",
    email: "",
    refferCode: "",
  });
  useEffect(() => {
    setDomainDetails(DD);
    return () => {};
  }, [DD]);

  useEffect(() => {
    document.documentElement.style.setProperty("--bodyBackGround", `#111111`);
  }, []);

  const handelInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsValidationWrong(false);
    setFormData({ ...formData, [name]: value });
  };

  const handelSubmit = async (e: any) => {
    console.log("click :: signup", formData);
    e.preventDefault();
    // return false;
    if (
      Validator.current.allValid() &&
      confirmationCode === parseInt(formData.validationCode) &&
      formData.username !== "" &&
      formData.password !== "" &&
      formData.confirmPassword !== "" &&
      formData.mobileNumber !== "" &&
      formData.firstName !== "" &&
      formData.email !== "" &&
      formData.refferCode !== "" &&
      formData.password === formData.confirmPassword
    ) {
      setIsValidationWrong(false);
      const ipDetails = await getApiLink({
        api: "https://ipapi.co/json/?key=wfzfdfQ4cUsaTVURUkj2oF6L51Y4jNE0IM2yE0V2xMyMkxjTsr",
      });
      console.log("call ip:  ipDetails :", ipDetails);

      let data = {
        api: USER_API.SIGN_UP,
        value: {
          user_name: formData.username,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          mobileNumber: formData.mobileNumber,
          currency: formData.currency,
          firstName: formData.firstName,
          email: formData.email,
          refferCode: formData.refferCode,
          domain: window.location.hostname,
          // domain: "velki777.live",
          city: ipDetails?.data?.city,
          state: ipDetails?.data?.region,
          country: ipDetails?.data?.country_name,
          ISP: ipDetails?.data?.org,
        },
      };
      await postApi(data)
        .then(function (loginData) {
          if (loginData) {
            //  notifyMessage(loginData.data.message)
            localStorage.setItem('isSignUpLogin', "true");
            console.log("::::::::;", loginData);
            let HeaderData = {
              ...loginData.data.data.roleAccess,
              user_name: loginData.data.data.user_name,
              remaining_balance: loginData.data.data?.balance,
              ...loginData.data.data,
            };
             dispatch({ type: 'HEADER_DETAILS', payload: HeaderData })
            cookies.set("skyTokenFront", loginData.data.data.token, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: "/",
            });
            dispatch({
              type: "AUTHENTICATION",
              payload: {
                isLogin: true,
                token: loginData.data.data.token,
                changePassword: loginData.data.data.newPassword,
              },
            });
            setTimeout(() => {
              window.location.reload();
            }, 100);
            // navigate("/");
            // setOpenModal(false);
            if (loginData.data.data.newPassword === true) {
              navigate("change/password");
            }
          }
        })
        .catch((err) => {
          notifyError(err.response.data.message);
          setIsError({
            flag: true,
            msg: err.response.data.message,
          });
          setIsValidationWrong(false);
          setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
          setFormData({ ...formData, validationCode: "" });
        });
    } else {
      console.log("else signup");

      Validator.current.showMessages();
      if (confirmationCode !== parseInt(formData.validationCode)) {
        setIsValidationWrong(true);
      }
      forceUpdate();
      setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
    }
  };

  const Validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  return (
    <>
      <div className="login-title">
        <div className="back" onClick={() => navigate("/")}>
          <img src="../../images/extra-icon/angle-left-white.png" height={22} />
        </div>
        <div className="center">
          <h5>Sign Up</h5>
        </div>
      </div>
      <div className="login-page-container">
        <div className="logo">
          <img src={getImageUrl(domainDetails?.logo)} />
        </div>

        <div className="form">
          <div className="form-line">
            <div className="form-label">Username</div>
            <div className="form-input">
              <input
                type="text"
                id="login-username"
                name="username"
                placeholder="4-15 char, allow number"
                className="form-control"
                value={formData?.username}
                onChange={handelInputChange}
                required
                minLength={4}
              />
            </div>
          </div>
          {Validator.current.message(
            "username",
            formData.username,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red"}}
            >
              {Validator.current.message(
                "username",
                formData.username,
                "required"
              )}
            </span>
          )}
          
          <div className="form-line">
            <div className="form-label">Password</div>
            <div className="form-input">
              <input
                type={isPassShow ? "text" : "password"}
                id="login-password"
                placeholder="password"
                name="password"
                className="form-control"
                value={formData?.password}
                onChange={handelInputChange}
                required
              />
            </div>
            <span
              onClick={() => setIsPassShow(!isPassShow)}
              // type="button"
              // className="position-absolute eye-img translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0  btn btn-primary"
              className="me-2"
              style={{ marginLeft: "10px" }}
            >
              {!isPassShow ? (
                <svg
                  stroke="currentColor"
                  fill="#ffffff"
                  stroke-width="0"
                  viewBox="0 0 640 512"
                  className="opacity-100 fs-5"
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
                </svg>
              ) : (
                <svg
                  stroke="currentColor"
                  fill="#ffffff"
                  stroke-width="0"
                  viewBox="0 0 576 512"
                  className="opacity-100 fs-5"
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                </svg>
              )}
            </span>
          </div>
          {Validator.current.message(
            "password",
            formData.password,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red" }}
            >
              {Validator.current.message(
                "password",
                formData.password,
                "required"
              )}
            </span>
          )}
          <div className="form-line">
            <div className="form-label">Confirm Password</div>
            <div className="form-input">
              <input
                type={isPassConfShow ? "text" : "password"}
                id="login-confirmPassword"
                placeholder="password"
                name="confirmPassword"
                className="form-control"
                value={formData?.confirmPassword}
                onChange={handelInputChange}
                required
              />
            </div>
            <span
              onClick={() => setIsPassConfShow(!isPassConfShow)}
              // type="button"
              // className="position-absolute eye-img translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0  btn btn-primary"
              className="me-2"
              style={{ marginLeft: "10px" }}
            >
              {!isPassConfShow ? (
                <svg
                  stroke="currentColor"
                  fill="#ffffff"
                  stroke-width="0"
                  viewBox="0 0 640 512"
                  className="opacity-100 fs-5"
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
                </svg>
              ) : (
                <svg
                  stroke="currentColor"
                  fill="#ffffff"
                  stroke-width="0"
                  viewBox="0 0 576 512"
                  className="opacity-100 fs-5"
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                </svg>
              )}
            </span>
          </div>
          {formData.confirmPassword !== "" &&
            formData.password !== "" &&
            formData.password !== formData.confirmPassword && (
              <span
                className="error"
                style={{ color: "red", }}
              >
                Please enter the same value again.
              </span>
            )}
          {Validator.current.message(
            "confirmPassword",
            formData.confirmPassword,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red",  }}
            >
              {Validator.current.message(
                "confirmPassword",
                formData.confirmPassword,
                "required"
              )}
            </span>
          )}
          <div className="form-line">
            <div className="form-label">Currency</div>
            <div className="form-input">
              <select
                id="currency"
                name="currency"
                className="form-control"
                value={formData?.currency}
                onChange={handelInputChange}
              >
                <option value="BDT">BDT</option>
                <option value="INR">INR</option>
                <option value="PKR">PKR</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form">
          <div className="form-line">
            <div className="form-label">Full Name</div>
            <div className="form-input">
              <input
                type="text"
                placeholder="Full Name"
                name="firstName"
                className="form-control"
                value={formData?.firstName}
                onChange={handelInputChange}
                required
              />
            </div>
          </div>
          {Validator.current.message(
            "Full Name",
            formData.firstName,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red" }}
            >
              {Validator.current.message(
                "Full Name",
                formData.firstName,
                "required"
              )}
            </span>
          )}
          <div className="form-line">
            <div className="form-label">Phone Number</div>
            <div className="form-input">
              <input
                type="number"
                placeholder="+880 1234567899"
                name="mobileNumber"
                className="form-control"
                value={formData?.mobileNumber}
                onChange={handelInputChange}
                required
              />
            </div>
          </div>
          {Validator.current.message(
            "Phone Number",
            formData.mobileNumber,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red" }}
            >
              {Validator.current.message(
                "Phone Number",
                formData.mobileNumber,
                "required"
              )}
            </span>
          )}
          <div className="form-line">
            <div className="form-label">Email</div>
            <div className="form-input">
              <input
                type="text"
                placeholder="Email"
                name="email"
                className="form-control"
                value={formData?.email}
                onChange={handelInputChange}
                required
              />
            </div>
          </div>
          {Validator.current.message(
            "Email",
            formData.email,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red" }}
            >
              {Validator.current.message(
                "Email",
                formData.email,
                "required"
              )}
            </span>
          )}
          <div className="form-line">
            <div className="form-label">Reffer Code</div>
            <div className="form-input">
              <input
                type="text"
                placeholder="Reffer Code"
                name="refferCode"
                className="form-control"
                value={formData?.refferCode}
                onChange={handelInputChange}
              />
            </div>
          </div>
          {Validator.current.message(
            "Reffer Code",
            formData.refferCode,
            "required"
          ) && (
            <span
              className="error"
              style={{ color: "red"}}
            >
              {Validator.current.message(
                "Reffer Code",
                formData.refferCode,
                "required"
              )}
            </span>
          )}
        </div>
        <div className="form">
          <div className="form-line">
            <div className="form-label">Verification Code</div>
            <div className="form-input">
              <input
                id="su-verifycode"
                type="text"
                placeholder="Enter 4 digit code"
                name="validationCode"
                className="form-control"
                value={formData?.validationCode}
                onChange={handelInputChange}
                required
              />
            </div>
            <div id="code">{confirmationCode}</div>
          </div>
          {isValidationWrong && (
            <span
              className="error"
              style={{ color: "red" }}
            >
              Please Enter Valid code.
            </span>
          )}
          <span id="su-verify-error"></span>
        </div>

        <div className="login-btn">
          <button onClick={handelSubmit}>Sign Up</button>
        </div>
        <div className="dont-have-acc">
          <span>Do you already have an account?</span>{" "}
          <span id="login-page-signupbtn" onClick={() => navigate("/login")}>
            Login
          </span>
        </div>
      </div>
    </>
  );
}
