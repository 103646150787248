import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ResetPassword() {
    const navigate = useNavigate();
    return (
        <>
            <div className="mob-header">
                <div className="back">
                    <img
                        src={"../../images/extra-icon/angle-left-white.png"}
                        height={22}
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="center">
                    <h5>Reset Password</h5>
                </div>
                <div className="help">
                    <img src={"../../images/extra-icon/message.png"} alt="Help" height={25} />
                    <span>Help</span>
                </div>
            </div>
            <div className="reset-passwrod-box">
                <div className="reset-form">

                    <div className="reset-form-line">
                        <div className="reset-form-label">Current Password</div>
                        <div className="reset-form-input"><input type="password" id="current-pass" placeholder="Current Password" /></div>
                        <div className="reset-form-action"></div>
                    </div>

                    <div className="reset-form-line">
                        <div className="reset-form-label">New Password</div>
                        <div className="reset-form-input"><input type="password" id="newpass-input" placeholder="New Password" /></div>
                        <div className="reset-form-action"></div>
                    </div>

                    <div className="reset-form-line">
                        <div className="reset-form-label">Confirm New Password</div>
                        <div className="reset-form-input"><input type="password" id="confirm-password" placeholder="Confirm New Password"  /></div>
                        <div className="reset-form-action"></div>
                    </div>
                </div>

                <div id="reset-pass-process"></div>

                <div className="req-box">
                    <img src={"../../images/extra-icon/exclamation-white.png"} height="18px" />
                    Password requirements<br/>
                    <div style={{margin:'20px 0'}}></div>
                    <span id="must-length">1. must be 6-20 characters</span><br/>
                    <span id="must-uppercase">2. must contain 1 uppercase alphabet(A-Z) at least</span><br />
                    <span id="must-lowercase">3. must contain 1 lowercase alphabet(a-z) at least</span><br />
                    <span id="must-number">4. must contain 1 number(0-9) at least</span><br />
                    <span id="allow-special">5. allow special characters(@$!%*#)</span> <br />
                </div>


                <div id="reset-confirm">Confirm</div>

            </div>
        </>
    )
}
